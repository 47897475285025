import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "worksafe-victoria--integration---tyro-health-sdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#worksafe-victoria--integration---tyro-health-sdk",
        "aria-label": "worksafe victoria  integration   tyro health sdk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WorkSafe Victoria	 integration - Tyro Health SDK`}</h1>
    <h2 {...{
      "id": "introduction-to-worksafe-victoria",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction-to-worksafe-victoria",
        "aria-label": "introduction to worksafe victoria permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction to WorkSafe Victoria`}</h2>
    <h3 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h3>
    <p>{`WorkSafe Victoria (Worksafe Vic or WSV) is the state health and safety regulator and manager of the Victorian workers compensation scheme. WorkSafe Victoria is also the central organisation which oversees payment of workers compensation claims, including payment of invoices from healthcare providers. `}<br /></p>
    <p>{`Workers who are injured in a workplace in Victoria will usually have claims paid by WorkSafe Victoria. Medicare, DVA and private health insurers will typically deny or defer claims to WorkSafe Victoria when the patient is eligible for that scheme. Approximately 83,000 workers were supported through $2.8 billion in WorkSafe Victoria scheme payments for FY 2020-2021. `}<br /></p>
    <p>{`Most healthcare providers in Victoria support WorkSafe Victoria. Under the scheme, injured workers will see a healthcare provider with provider invoices issued directly to WorkSafe Victoria rather than for intermediate payment by the patient. Under the old method, providers would email or post invoices to WorkSafe Victoria which might be paid in 4-6 weeks but without transparency over the claim status. `}<br /></p>
    <p>{`In partnership with Tyro Health, WorkSafe Victoria now supports straight-through electronic claims submission and next day payment for approved claims. Problematic claims, such as invalid claim numbers, can be identified in real time expediting the invoice process. Providers can self-check the status of invoices for greater transparency. `}<br /></p>
    <p>{`Importantly, there is no fee to service providers or partners to raise digital claims to WorkSafe Victoria .There is no registration fee, ongoing cost, fee per claim or settlement.`}<br /></p>
    <p>{`WorkSafe Victoria claims can be submitted electronically by partners through an approved integration method including the Tyro Health SDK or batch file. Claim status and settlement details are provided by Tyro Health and can be obtained via a webhook set for in the claim payload or polled via an Invoice status API.  `}<br /></p>
    <p>{`Information on the scheme and details for healthcare provider services are provided at: `}<a parentName="p" {...{
        "href": "https://www.worksafe.vic.gov.au/information-for-providers"
      }}>{`https://www.worksafe.vic.gov.au/information-for-providers`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      